import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import Header from './compontens/Header';
import Footer from './compontens/Footer';
import Popup from './compontens/Popup';



// Dynamically importing components
const Home = lazy(() => import('./pages/Home'));
const Rmc = lazy(() => import('./pages/Rmc'));
const GaneshCP = lazy(() => import('./pages/GaneshCP'));
const GaneshBS = lazy(() => import('./pages/GaneshBS'));


const UserLayout = () => {
  return (
    <>
    
      <Header />
      <Outlet />
      <Footer />

    </>
  )
}

const AdminLayout = () => {
  return (
    <>

      <Outlet />

    </>
  )
}


const App = () => {
  return (
    <Router>
      <div>
        {/* ---- loader animation ---- */}

        <Suspense fallback={
          <div className='h-screen flex items-center justify-center'>

            <div class="flex-col gap-4 w-full flex items-center justify-center">
              <div
                class="w-20 h-20 border-4 border-transparent text-blue-400 text-4xl animate-spin flex items-center justify-center border-t-blue-400 rounded-full"
              >
                <div
                  class="w-16 h-16 border-4 border-transparent text-red-400 text-2xl animate-spin flex items-center justify-center border-t-red-400 rounded-full"
                ></div>
              </div>
            </div>

          </div>
        }>

          <Popup />

          <Routes>

            {/* userLayout */}
            <Route path="/" element={<UserLayout />}>
              <Route index element={<Home />} />
              <Route path='/sairam_rmc' element={<Rmc />} />
              <Route path='/sai_ganesh_concrete_products' element={<GaneshCP />} />
              <Route path='/sai_ganesh_blue_sand' element={<GaneshBS />} />
            </Route>


            {/* AdminLayout */}
            <Route path='/admin' element={<AdminLayout />}>


            </Route>


          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}


export default App